/* Container holding the image and the text */
$red: #f37272;
$brown: #584e4a;
$whitey: #f0f0f0;

$duration: 0.2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

@font-face {
  font-family: "DidotRegular";
  src: local("DidotRegular"),
    url("./fonts/Didot_Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "DidotBold";
  src: local("DidotBold"), url("./fonts/Didot_Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "JosephinBoldItalic";
  src: local("JosephinBoldItalic"),
    url("./fonts/JosefinSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
}
* {
  line-height: 1.3;
}
.outer-div {
  background-color: #d8d7d6;
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom right text */
.bottom-center {
  position: absolute;
  top: 88%;
  left: 35%;
  width: 60%;
}

/* Centered text */
.centered {
  position: absolute;
  top: 35%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 80%;
  animation-fill-mode: none;
}

.new-cover {
  width: 100%;
}

.floating-subtitle {
  position: relative;
  top: -9%;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid white;
  font-size: 16px;
  font-family: "DidotRegular";
}

.floating-subtitle-time {
  color: white;
  padding-bottom: 10px;
}

.rsvp-button-container {
  display: absolute;
  background-color: #dcd7d0;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rsvp-line {
  width: 60%;
  border-bottom: 1.5px solid #dcd7d0;
}

.underlined {
  color: black;
  text-decoration: none;
  background-image: linear-gradient(to right, #fdf5f6 0, #fdf5f6 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
  &:hover {
    background-size: 100% 100%;
  }
  &--offset {
    background-position: 0 0em;
  }
}

.spotify-success {
  width: 70px;
}

.rsvp-button {
  font-family: "JosephinBoldItalic";
  font-weight: 700;
  color: #dcd7d0;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  text-align: center;
}

.right-arrow {
  width: 20px;
  padding-left: 10px;
  color: #dcd7d0;
}

/* Page 2 */

.page2-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  background-color: white;
}

.page2-title-container {
  text-align: right;
  color: #e5a795;
  font-size: 24px;
  font-family: "DidotBold";
  border-bottom: 1px solid #e5a795;
}

.page2-sub-container {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
}

.spotify-container {
  padding: 30px 0;
  height: 150px;
}

.add-to-playlist {
  width: 100%;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  .spotify-line {
    width: 65%;
    border-bottom: 1.5px solid #dcd7d0;
  }
}

/* Page 3 container */
.page3-container {
  padding: 30px 0;
  text-align: center;
  background-color: #fdf5f6;
  color: #ffcace;
  font-size: 16px;
  font-family: "DidotBold";
}

.signature {
  max-width: 25%;
  height: 22px;
}

/* RSVP Page */
.rsvp-container {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  background-color: white;
}

.rsvp-title {
  font-size: 24px;
  width: 120px;
  text-align: right;
  padding: 20px;
}

.back-to-home {
  text-align: center;
  font-size: 16px;
  font-family: "DidotBold";
  padding-bottom: 20px;
}

.rsvp-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #dcd7d0;
  color: white;
  padding-top: 30px;
  font-family: "DidotBold";
}

.rsvp-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  font-size: 16px;
  height: 100vh;
}

.rsvp-form-label {
  font-size: 16px;
}

.right-arrow-spotify {
  width: 15px;
  padding-left: 10px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  // color: #cb4f2a;
  font-weight: 200;
  font-family: "Josefin Sans", sans-serif;
}
.MuiTypography-body1 {
  font-family: "Josefin Sans", sans-serif;
}
.materialUIInput {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

button {
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  background: #fbeeef;
}

input[type="submit"] {
  background: #dcd7d0;
  margin-top: 30px;
  font-family: "Josefin Sans", sans-serif;
}

input[type="submit"]:hover {
  background: darkgrey;
  color: white;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
  background: #cb4f2a;
  color: white;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
@media (min-width: 340px) {
  .add-to-playlist .spotify-line {
    width: 55%;
  }
}
@media (min-width: 375px) {
  .photo-1 {
    max-height: 300px;
  }
  .floating-title {
    left: 15%;
    top: 17%;
    font-size: 120px;
  }
  .photo-2 {
    max-height: 300px;
  }
  .photo-3 {
    max-height: 300px;
  }
  .empty-box {
    height: 200px;
  }
  .floating-subtitle {
    font-size: 16px;
    top: -8%;
  }
  .rsvp-line {
    width: 50%;
  }
  .add-to-playlist .spotify-line {
    width: 55%;
  }
}

@media (min-width: 425px) {
  .rsvp-line {
    width: 45%;
  }
  .right-arrow {
    width: 20px;
  }
  .add-to-playlist .spotify-line {
    width: 45%;
  }
}

@media (min-width: 768px) {
  .floating-title {
    left: 17%;
    top: 20%;
    font-size: 200px;
  }
  .photo-2 {
    max-height: 400px;
  }
  .photo-3 {
    max-height: 400px;
  }
  .floating-subtitle {
    font-size: 28px;
    top: -8%;
  }
  .empty-box {
    height: 300px;
  }
  .rsvp-button {
    font-size: 25px !important;
  }
  .rsvp-line {
    width: 50%;
  }
  .right-arrow {
    width: 40px;
    padding-left: 10px;
  }
  .rsvp-button-container {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .page2-container {
    padding: 50px;
  }
  .page2-title-container {
    font-size: 40px;
  }
  .page2-sub-container {
    font-size: 30px;
    padding: 30px 0;
  }
  .transport-car {
    padding-bottom: 40px;
  }
  .transport-sub {
    font-size: 28px;
  }
  .page3-container {
    font-size: 21px;
    padding-bottom: 60px;
  }
  .rsvp-form-label {
    font-size: 30px;
  }
  .add-to-playlist .spotify-line {
    width: 35%;
    padding-top: 20px;
  }
}

@media (min-width: 1024px) {
  .container {
    /* max-width: 768px; */
    margin: 0 20%;
  }
  .photo-1 {
    max-height: 400px;
  }
  .floating-title {
    left: 25%;
    top: 10%;
    font-size: 200px;
  }
  .photo-2 {
    max-height: 400px;
  }
  .photo-3 {
    max-height: 400px;
  }
  .floating-subtitle {
    font-size: 25px;
    top: -9%;
  }
  .empty-box {
    height: 250px;
  }
  .rsvp-button-container {
    margin: 0 20%;
  }
  .rsvp-button {
    font-size: 50px;
  }
  .rsvp-line {
    width: 50%;
  }
  .page2-container {
    margin: 0 20%;
  }
  .page3-container {
    margin: 0 20%;
  }
  .rsvp-container {
    margin: 0 20%;
  }
  .page2-title-container {
    font-size: 40px;
  }
  .page2-sub-container {
    font-size: 30px;
    padding: 30px 0;
  }
  .transport-car {
    padding-bottom: 40px;
  }
  .transport-sub {
    font-size: 28px;
  }
  .page3-container {
    font-size: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .right-arrow {
    width: 30px;
    padding-left: 10px;
  }
  .right-arrow-spotify {
    width: 15px;
    padding-left: 10px;
  }
  .add-to-playlist {
    padding-top: 40px;
  }
  .add-to-playlist .spotify-line {
    width: 40%;
  }
  .rsvp-form-label {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    /* max-width: 768px; */
    margin: 0 30%;
  }
  .photo-1 {
    max-height: 800px;
  }
  .floating-title {
    left: 37%;
    top: 20%;
    font-size: 150px;
  }
  .photo-2 {
    max-height: 800px;
  }
  .photo-3 {
    max-height: 800px;
  }
  .floating-subtitle {
    font-size: 24px;
    top: -9%;
  }
  .empty-box {
    height: 250px;
  }
  .rsvp-button {
    font-size: 60px;
  }
  .rsvp-button-container {
    margin: 0 30%;
  }
  .rsvp-container {
    margin: 0 30%;
  }
  .page2-container {
    margin: 0 30%;
  }
  .page3-container {
    margin: 0 30%;
  }
  .rsvp-line {
    width: 40%;
  }
}

.loading-screen {
  background-color: black;
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
